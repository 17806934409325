import React, { useEffect, useState } from "react";
import utils from "../../../utils/utils";
import { Circle, OpenInNew } from "@mui/icons-material";
import {
    Typography,
    Stack,
    IconButton,
    Menu,
    MenuItem,
    Grid,
    Divider,
    Box,
    Tooltip,
    Avatar,
    Modal,
    Backdrop
} from "@mui/material";
import Loader from "../../../pages/account/Loader";
import tableStyle from "../../../styles/theme/pageStyling/TableStyle.module.css"
import CustomTable from "../../../common/CustomTable/CustomTable";
import { getInfluencersTableHeaders } from "../../../utils/tableHeaders";
import ViewUpdateDialog from "../../list/ViewUpdateDialog";
import ContactCard from "./ContactCard";
import { snapshotHeaderIcons, textColor } from "../snapshot";
import { MoreVertOutlined } from "@mui/icons-material";
import { getTimeZone } from "../../../utils/exportData";
import MyPaper from "../../general/MyPaper";
import { height } from "@mui/system";
import { onDownloadHandle } from "../../../utils/exportData";
import ExportFiles from "../../../pages/account/ExportFiles";
import stringAvatar from "../../../utils/avatar";
import CampaignProfileModal from "./CampaignProfileModal";
import configs from "../../../config/main.json";
import { useDispatch, useSelector } from "react-redux";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const InfluencerList = (props) => {
    const user = useSelector((state) => state.user);
    const [isLoaded, setIsLoaded] = useState(false);
    const [influencerList, setInfluencerList] = useState([]);
    const [dataFound, setDataFound] = useState(false); // shows that we have/recive list data from API or not
    const [anchorEl, setAnchorEl] = useState([]);
    const [viewUpdateDialog, setViewUpdateDialog] = useState(false);
    const [viewContactDialog, setViewContactDialog] = useState(false);
    const [selectedInfluencer, setSelectedInfluencer] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [tablePageNo, setTablePageNo] = useState(0); // refers to page no of pagination (default - 0)
    const [tableRowsPerPage, setTableRowsPerPage] = useState(10);
    const [sortCol, setSortCol] = useState("lm.updatedAt"); //refers on what base all col are sorted
    const [sortOrder, setSortOrder] = useState("desc"); //refers on what base all rows are sorted
    const [isExport, setIsExport] = useState(0); // refers we want to export or not, 0 - not, 1 - yes
    const [isRefreshed, setIsRefreshed] = useState(false);
    const [mimeType] = useState("csv"); //refers to the type in which we want to export
    const [account] = useState(0);
    const [client] = useState(0);
    const [lastPostedStatus] = useState("All");
    const timeZone = getTimeZone(); // to set the time on platform
    const [totalRecords, setTotalRecords] = useState(); // refers to total no of accounts
    const [resetPageNo, setResetPageNo] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [openProfileModal, setOpenProfileModal] = React.useState(false);
    const styles = {
        noData: {
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            textAlign: "center",
            fontSize: "large",
            fontStyle: "italic",
        },
    };

    const platform = props.selectedPlatform; //social media platform
    const campId = props.campId;

    useEffect(() => {
        setTablePageNo(0);
    }, [platform]);

    useEffect(() => {

        setIsLoaded(() => false);
        getInfluencerList(
            campId,
            platform,
            tablePageNo,
            tableRowsPerPage,
            sortCol,
            sortOrder,
            isExport,
            isRefreshed,
            mimeType,
            account,
            client,
            timeZone,
            lastPostedStatus
        );
    }, [campId, platform, tablePageNo, tableRowsPerPage, sortCol, sortOrder, isExport, isRefreshed, mimeType]);

    const getInfluencerList = async (
        campId,
        platform,
        tablePageNo,
        tableRowsPerPage,
        sortCol,
        sortOrder,
        isExport,
        isRefreshed,
        mimeType,
        account,
        client,
        timeZone,
        lastPostedStatus
    ) => {
        setIsLoaded(false);
        utils.getInfluencersAnalysis(
            campId,
            platform,
            tablePageNo + 1,
            tableRowsPerPage,
            sortCol,
            sortOrder,
            isExport,
            isRefreshed,
            mimeType,
            account,
            client,
            timeZone,
            lastPostedStatus
        ).then((data) => {
            let newData = [];
            if (data.records !== undefined && data.records !== null) {
                Object(data.records).forEach((item, index) => {
                    newData.push({ sl: index + 1, ...item });
                    newData[index]["displayName"] = newData[index]["name"];
                    newData[index]["age_range"] = newData[index]["ageRange"];
                    newData[index]["profile_url"] = newData[index]["profileUrl"];
                    newData[index]["total_followers"] = newData[index]["followerCount"];

                    if (newData[index]["type"] === "direct") {
                        newData[index]["displayName"] = newData[index]["name"] + " *";
                    }
                });
                setInfluencerList(newData);
                setTotalRecords(data.total_records)
                setDataFound(true);
                setIsLoaded(true);
            } else {
                setDataFound(false);
                setIsLoaded(true);

                if (isExport === 1) {
                    const csvData = data;
                    const csvBlob = new Blob([csvData], { type: 'text/csv' });
                    onDownloadHandle(csvBlob, "Influencer." + mimeType);
                    setIsExport(0);
                }
            }
        });
    };

    const openProfileUrl = (influencer) => {
        if (influencer.profileUrl.length === 1) {
            window.open(influencer.profileUrl, "_blank");
        } else {
            setSelectedInfluencer(influencer);
            setOpenProfileModal(true);
        }
    };

    const closeProfileModal = () => {
        setOpenProfileModal(false);
    };

    const menuItemClickHandler = (itemIdx, action) => {
        //console.log(action);
        if (action === "updateProfile") {
            setViewUpdateDialog(true);
        }
        if (action === "viewContact") {
            setViewContactDialog(true);
        }
        const influencer = influencerList[itemIdx];
        if (influencer) {
            setSelectedInfluencer({
                id: influencer.iid,
                ...influencer
            });
        }
    };

    const dialogCloseHandler = (event) => {
        setViewUpdateDialog(false);
        setViewContactDialog(false);
        setSelectedInfluencer(null);

        if (event) {
            getInfluencerList(campId, platform);
        }
    };

    const setInfluencerAccountList = (list) => {
        setInfluencerList(list);
    };

    const handleInformationChange = (newPage, rowsPerPage, order, orderBy) => {
        setTablePageNo(newPage.page);
        setResetPageNo(false);
        setIsChanged(!isChanged);
        setTableRowsPerPage(newPage.rowsPerPage);
        setSortCol(newPage.orderBy);
        setSortOrder(newPage.order);
    };

    // function to set in which from(csv) we want to export and decide whether we want to export or not
    const sendExportSelected = (event) => {
        setIsExport(event === "csv" ? 1 : 0);
    };

    useEffect(() => {
        const handleClick = (event, index) => {
            const anchorEl = [];
            for (let i = 0; i < influencerList.length; i++) {
                if (i === index) anchorEl.push(event.currentTarget);
                else anchorEl.push(null);
            }
            setAnchorEl(anchorEl);
        };

        const handleClose = (i) => {
            const anchorEl = [];
            for (let i = 0; i < influencerList.length; i++) {
                anchorEl.push(null);
            }
            setAnchorEl(anchorEl);
        };

        const data = [];
        if (influencerList) {
            influencerList.forEach((item, index) => {
                const {
                    ageRange,
                    age_range,
                    displayName,
                    email,
                    engRate,
                    engagement,
                    followerCount,
                    gender,
                    iid,
                    lastPosted,
                    listName,
                    location,
                    name,
                    notes,
                    photoUrl,
                    platform,
                    posts,
                    profileUrl,
                    profile_url,
                    sl,
                    status_name,
                    total_followers,
                    type,
                    userName,
                } = item;

                const actions = (
                    <div>
                        <>
                            {/* <MenuItem
                                key={1}
                                onClick={() => {
                                    menuItemClickHandler(index, "updateProfile");
                                    handleClose(index);
                                }}
                            >
                                Update Profile
                            </MenuItem> */}
                        </>

                        <>
                            <Divider sx={{ my: 0.5 }} />
                            <MenuItem
                                key={1}
                                onClick={() => {
                                    menuItemClickHandler(index, "viewContact");
                                    handleClose(index);
                                }}
                            >
                                View Contact Details
                            </MenuItem>
                        </>
                    </div>
                );

                const open = anchorEl.map((val) => val !== null);

                const object = {
                    id: index + 1,
                    name: (
                        <Grid container spacing={0} alignItems="center">
                            <Grid item xs={2} style={{ marginBlock: "auto", marginRight: "0.2vw" }}>
                                <Avatar
                                    variant="circle"
                                    src={photoUrl || ""}
                                    {...stringAvatar(name)}
                                    style={{ width: "40px", height: "40px" }}
                                    onError={(e) => {
                                        e.target.src = configs.PROFILE_IMG;
                                    }}
                                />
                            </Grid>
                            <Grid item xs={7} style={{ marginTop: "5px" }} >
                                <p className={styles.bodycell}>
                                    <span>{displayName}</span>
                                    {profileUrl !== "" && (
                                        <IconButton onClick={() => openProfileUrl(item)}>
                                            <OpenInNew fontSize="small" />
                                        </IconButton>
                                    )}
                                </p>
                            </Grid>
                            <Grid item xs={2}>
                                <Grid container spacing={3}>
                                    {platform.map((platform) => (
                                        <Grid item xs={1} className={styles.bodycell}>
                                            <Typography sx={textColor[platform]}>
                                                {snapshotHeaderIcons[platform]}
                                            </Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    ),
                    location: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                {location !== "" ? (
                                    <Tooltip title={location}>
                                        <p style={{ cursor: "pointer" }} className={styles.bodycell}>{location.split(',')[0]}</p>
                                    </Tooltip>
                                ) : (
                                    <p className={styles.bodycell} style={{ paddingLeft: "10px" }}>{"-"}</p>
                                )}
                            </Grid>
                        </Grid>
                    ),
                    gender: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                {gender ? (
                                    <p className={styles.bodycell}>{gender}</p>
                                ) : (
                                    <p className={styles.bodycell}>{"-"}</p>
                                )}
                            </Grid>
                        </Grid>
                    ),
                    ageRange: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                {ageRange ? (
                                    <p className={styles.bodycell}>{ageRange}</p>
                                ) : (
                                    <p className={styles.bodycell}>{"-"}</p>
                                )}
                            </Grid>
                        </Grid>
                    ),
                    followers: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                <p className={styles.bodycell}>{utils.convertToInternationalNumberSystem(followerCount)}</p>
                            </Grid>
                        </Grid>
                    ),
                    postCount: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                {posts > 0 ? (
                                    <p className={styles.bodycell}>{posts}</p>
                                ) : (
                                    <p className={styles.bodycell}>{"-"}</p>
                                )}
                            </Grid>
                        </Grid>
                    ),
                    engagement: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px" }}>
                                <p className={styles.bodycell} style={{ paddingLeft: "10px" }}>{utils.convertToInternationalNumberSystem(engagement)}</p>
                            </Grid>
                        </Grid>
                    ),
                    engagementRate: (
                        <Grid container>
                            <Grid item xs={12} sx={{ paddingTop: "20px", width: "50px" }}>
                                <p className={styles.bodycell}>{engRate + "%"}</p>
                            </Grid>
                        </Grid>
                    ),
                    options: (
                        <Grid container>
                            <Grid item xs={3}>
                                <>
                                    {" "}
                                    <IconButton onClick={(event) => handleClick(event, index)} size="small" sx={{ ml: 2 }}>
                                        <MoreVertOutlined />
                                    </IconButton>
                                    <Box style={{ borderRadius: "30%" }}>
                                        <Menu
                                            anchorEl={anchorEl[index]}
                                            getContentAnchorEl={null}
                                            open={open[index]}
                                            onClose={handleClose}
                                            className={tableStyle.menuitem}
                                        >
                                            {actions}
                                        </Menu>
                                    </Box>
                                </>
                            </Grid>
                        </Grid>
                    ),
                };

                data.push(object);
            });
            setTableData(data);
        }
    }, [influencerList, anchorEl]);

    const influencersTableHeaders = getInfluencersTableHeaders;

    const renderContentHandler = () => {
        let content = null;
        if (isLoaded) {
            if (dataFound) {
                content = (
                    <>
                        <div id="table">
                            {/* This is a custom made component, take care while changing field names */}
                            <CustomTable
                                tableHeaders={influencersTableHeaders}
                                bodyColumns={tableData}
                                sendInformation={handleInformationChange}
                                totalRows={totalRecords}
                                pageNo={tablePageNo}
                                rowsPerPageNo={tableRowsPerPage}
                                sortOrder={sortOrder}
                                sortOrderBy={sortCol}
                            />
                        </div>
                    </>
                );
            }
            else {
                content = (
                    <MyPaper>
                        <Grid container spacing={2}>
                            {/* No account found for this account */}
                            <Grid item xs={12} >
                                <p style={{ padding: "40px", textAlign: "center" }}> Sorry, no matching records found</p>
                            </Grid>
                        </Grid>
                    </MyPaper>
                );
            }
        } else content = <Loader />;

        return content;
    };

    return (
        <>
            <Box mt={4} mb={2} display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
                <Stack direction="row" justifyContent="start" sx={{ marginTop: "40px" }}>
                    <Typography variant="h4" sx={props.parentStyle.typography}>
                        All Influencers
                    </Typography>
                </Stack>
                <ExportFiles sendExportSelected={sendExportSelected} />
            </Box>
            <Box my={1}>{renderContentHandler()}</Box>

            {viewUpdateDialog && (
                <ViewUpdateDialog
                    influencerAccounts={influencerList}
                    platform={selectedInfluencer.platform}
                    influencerAccount={selectedInfluencer}
                    viewUpdateDialog={viewUpdateDialog}
                    closeViewUpdateDialog={dialogCloseHandler}
                    setInfluencerAccountList={setInfluencerAccountList}
                />
            )}
            {viewContactDialog && (
                <ContactCard
                    platform={selectedInfluencer?.platform}
                    influencerAccount={selectedInfluencer}
                    viewContactDialog={viewContactDialog}
                    closeContactDialog={dialogCloseHandler}
                    user = {user}
                />
            )}

            {openProfileModal && (
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openProfileModal}
                    onClose={() => setOpenProfileModal(true)}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 500,
                        },
                    }}
                >
                    <CampaignProfileModal closeProfileModal={closeProfileModal} selectedInfluencer={selectedInfluencer} />
                </Modal>
            )}
        </>
    )

}
export default InfluencerList;
