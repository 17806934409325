import { Typography, Drawer, List, ListItemButton, ListItemIcon, ListItemText, useTheme, Chip } from "@mui/material";
import {
  CampaignTwoTone as CampaignIcon,
  Groups,
  PersonTwoTone as PersonIcon,
  Subject,
  AdminPanelSettings,
} from "@mui/icons-material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { alpha, styled } from "@mui/system";
import { bool, func, number } from "prop-types";
import { APP_NAME , BASE_IMAGE } from "../../../config/main.json";
import PersonAddAltTwoToneIcon from "@mui/icons-material/PersonAddAltTwoTone";
import RecordVoiceOverTwoToneIcon from "@mui/icons-material/RecordVoiceOverTwoTone";
import ListAltIcon from "@mui/icons-material/ListAlt";
// import FeedIcon from "@mui/icons-material/Feed";
import ConstructionIcon from "@mui/icons-material/Construction";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useEffect, useState } from "react";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useSelector } from "react-redux";

const logo = `${BASE_IMAGE}logo-dark.png`;

const menuItemsData = [
  /*{
    name: 'Dashboard',
    path: '/account/',
    icon: <HomeIcon sx={{ fontSize: '1.6em' }} />
  },*/
  {
    name: "Campaigns",
    path: "/campaigns",
    icon: <CampaignIcon sx={{ fontSize: "1.6em" }} />,
  },
  {
    name: "Account Mgmt",
    path: "#",
    icon: <PersonIcon sx={{ fontSize: "1.6em" }} />,
    options: [
      {
        name: "Accounts",
        path: "/accounts",
      },
      {
        name: "Brands",
        path: "/brands",
      },
    ],
  },
  {
    name: "Influencers",
    path: "#",
    options: [
      {
        name: "Discover",
        path: "/advance/discovery",
      },
      {
        name: "Analyze",
        path: "/advance/analyze",
      },
      {
        name: "Add /  Invite",
        path: "/influencers",
        icon: <PersonAddAltTwoToneIcon sx={{ fontSize: "1.6em" }} />,
      },
      {
        name: "Manage",
        path: "/discovery",
        icon: <RecordVoiceOverTwoToneIcon sx={{ fontSize: "1.6em" }} />,
      },
    ],
    icon: <RecordVoiceOverTwoToneIcon sx={{ fontSize: "1.6em" }} />,
    chip: "Beta",
  },
  {
    name: "List",
    path: "/list",
    icon: <ListAltIcon sx={{ fontSize: "1.6em" }} />,
  },
  // {
  //   name: "Drafts",
  //   path: "/posts",
  //   icon: <FeedIcon sx={{ fontSize: "1.6em" }} />,
  // },
  {
    name: "Tools",
    path: "/tools",
    options: [
      {
        name: "Sentiment Analysis",
        path: "/tools/sentiments",
      },
      {
        name: "Translator",
        path: "/tools/translate",
      },
      // {
      //   name: "Profile Analyizer",
      //   path: "/tools/profileAnalyze",
      //   chip: "Beta",
      // },
    ],
    open: false,
    icon: <ConstructionIcon sx={{ fontSize: "1.6em" }} />,
  },
  {
    name: "BD Tools",
    path: "/bdTools",
    options: [
      {
        name: "Campaign Brief",
        path: "/bdTools/campaignBrief",
      },
      {
        name: "Inbound Leads",
        path: "/bdTools/inboundLeads",
      },
      {
        name: "Empowr Queries",
        path: "/bdTools/empowrQueries",
      },
    ],
    open: false,
    icon: <Groups sx={{ fontSize: "1.6em" }} />,
  },
  {
    name: "Linkedin Discovery",
    path: "/linkedin/discovery",
    icon: <LinkedInIcon sx={{ fontSize: "1.6em" }} />,
  },
  {
    name: "User Access",
    path: "/userAccess",
    options: [
      {
        name: "Aifluence Users",
        path: "/userAccess/aifluence",
      },
      {
        name: "External Clients",
        path: "/userAccess/empowr",
      },
      {
        name: "People Assignment",
        path: "/userAccess/PeopleAssignment",
      },
    ],
    icon: <AdminPanelSettings sx={{ fontSize: "1.6em" }} />,
    access: ["super-user", "manager"],
  },
  {
    name: "User Action Logs",
    path: "/userLogs",
    icon: <Subject sx={{ fontSize: "1.6em" }} />,
    access: ["super-user", "manager"],
  },
];

const StyledRouterLink = styled(RouterLink)(({ theme }) => ({
  color: theme.palette.text.primary,
}));
const Logo = styled("img")({});
const Nav = styled("nav")({});
const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));
const ListSubItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 40,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },
}));
const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const ListItemRightIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "right",
});

const AccountNav = ({ drawerOpen, drawerWidth, handleDrawerToggle }) => {
  const theme = useTheme();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  const [menuItems, setMenuItems] = useState(menuItemsData);
  const [drawer, setDrawer] = useState();
  const [first, setFirst] = useState(true);

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "medium",
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    "&:before": { display: "block" },
  };

  const toggleArrow = (index) => {
    const newMenu = menuItems;
    newMenu[index].open = !newMenu[index].open;
    setMenuItems(newMenu);
    createDrawer(newMenu);
  };

  const createDrawer = (menuItemsData) => {
    const drawer = (
      <>
        <RouterLink to="/" style={{ textAlign: "center" }}>
          <Logo
            src={logo}
            alt={APP_NAME}
            sx={{
              display: {
                md: "inline-block",
                xs: "none",
              },
              height: {
                xs: "40px !important",
                md: "initial",
              },
              margin: {
                xs: "14px 0px",
                md: "30px",
              },
            }}
          />
        </RouterLink>
        <List>
          {menuItemsData.map((item, index) => {
            if (item.access !== undefined && !item.access.includes(user.group)) {
              return null;
            }
            if (item.options === undefined) {
              const isActive = location.pathname === item.path || location.pathname.startsWith(item.path); // (location.pathname === item.path);

              return (
                <StyledRouterLink key={item.name} to={item.path} onClick={handleDrawerToggle}>
                  <ListItemStyle sx={{ ...(isActive && activeRootStyle) }}>
                    <ListItemIconStyle
                      sx={{
                        minWidth: "45px",
                        ...(isActive && {
                          transform: "scale(1.3)",
                          color: "primary.main",
                        }),
                      }}
                    >
                      {item.icon}
                    </ListItemIconStyle>
                    <ListItemText
                      primary={
                        <Typography
                          variant="body2"
                          sx={{
                            ...(isActive && { fontWeight: 500 }),
                            whiteSpace: "nowrap",
                          }}
                        >
                          {item.name}
                        </Typography>
                      }
                    />

                    {item.chip !== undefined && (
                      <Chip
                        style={{
                          marginLeft: "6px",
                          transform: "scale(0.8)",
                          background: "#5682f0",
                          color: "white",
                        }}
                        label={item.chip}
                      />
                    )}
                  </ListItemStyle>
                </StyledRouterLink>
              );
            } else {
              let isActive = false;
              item.options.forEach((option) => {
                if (location.pathname === option.path || location.pathname.startsWith(option.path)) {
                  isActive = true;
                }
              });

              return (
                <>
                  <div onClick={() => toggleArrow(index)}>
                    <ListItemStyle sx={{ ...(isActive && activeRootStyle) }}>
                      <ListItemIconStyle
                        sx={{
                          minWidth: "45px",
                          ...(isActive && {
                            transform: "scale(1.3)",
                            color: "primary.main",
                          }),
                        }}
                      >
                        {item.icon}
                      </ListItemIconStyle>
                      <ListItemText
                        primary={
                          <Typography variant="body2" sx={{ ...(isActive && { fontWeight: 500 }) }}>
                            {item.name}
                          </Typography>
                        }
                      />
                      <ListItemRightIconStyle
                        sx={{
                          minWidth: "45px",
                          ...(isActive && {
                            transform: "scale(1.3)",
                            color: "primary.main",
                          }),
                        }}
                      >
                        {menuItems[index].open && <KeyboardArrowUpIcon />}
                        {!menuItems[index].open && <KeyboardArrowDownIcon />}
                      </ListItemRightIconStyle>
                    </ListItemStyle>
                  </div>
                  {item.open &&
                    item.options.map((option) => {
                      const isActive = location.pathname === option.path || location.pathname.startsWith(option.path);

                      return (
                        <StyledRouterLink key={option.name} to={option.path} onClick={handleDrawerToggle}>
                          <ListSubItemStyle sx={{ ...(isActive && activeRootStyle) }}>
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body2"
                                  style={{ marginLeft: "30px" }}
                                  sx={{ ...(isActive && { fontWeight: 500 }) }}
                                >
                                  {option.name}
                                  {option.chip !== undefined && (
                                    <Chip
                                      style={{
                                        marginLeft: "6px",
                                        transform: "scale(0.8)",
                                        background: "#5682f0",
                                        color: "white",
                                      }}
                                      label={option.chip}
                                    />
                                  )}
                                </Typography>
                              }
                            />
                          </ListSubItemStyle>
                        </StyledRouterLink>
                      );
                    })}
                </>
              );
            }
          })}
        </List>
      </>
    );

    setDrawer(drawer);
  };

  useEffect(() => {
    if (first) {
      const newMenu = menuItems;
      newMenu.forEach((item, index) => {
        if (location.pathname.startsWith(item.path) && item.options !== undefined) {
          newMenu[index].open = true;
        }
      });
      setMenuItems(newMenu);
      createDrawer(newMenu);
      setFirst(false);
    } else {
      createDrawer(menuItems);
    }
  }, [location.pathname, user.group]);

  return (
    <Nav
      aria-label="sidebar"
      sx={{
        flexShrink: {
          md: 0,
        },
      }}
    >
      <Drawer
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        variant="temporary"
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        disableScrollLock={true}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        sx={{
          display: { xs: "none", md: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        variant="permanent"
        open
      >
        {drawer}
      </Drawer>
    </Nav>
  );
};

AccountNav.propTypes = {
  drawerOpen: bool.isRequired,
  handleDrawerToggle: func.isRequired,
  drawerWidth: number.isRequired,
};

export default AccountNav;
