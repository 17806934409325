import {
  CachedTwoTone,
  EmailTwoTone,
  LabelTwoTone,
  MoreVertOutlined,
  OpenInNewTwoTone,
  PermContactCalendarTwoTone,
  PersonTwoTone,
  StarBorderTwoTone,
  CardMembershipTwoTone,
  ContactPageTwoTone,
} from "@mui/icons-material";
import { Box, Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { array, func, object } from "prop-types";
import { useState } from "react";
import { inviteInfluencerByList } from "../../services/influencer/InfluencerInvite";
import {
  addNoteForInfluencer,
  influencerAsLeader,
  removeInfluencerFromList,
  generateCertificate,
  downloadCertificate,
} from "../../services/list/ListInfluencerMap";
import styles from "../../styles/theme/pageStyling/ListDetails.module.css";
import ViewContractDialog from "./ViewContractDialog";
import ViewDetailedProfileDialog from "./ViewDetailedProfileDialog";
import ViewNoteDialog from "./ViewNoteDialog";
import ViewUpdateDialog from "./ViewUpdateDialog";
import FileSaver from "file-saver";
import ViewContactDialog from "./ViewContactDialog";
import { useDispatch, useSelector } from "react-redux";
import { PLATFORM_NAME } from "../../config/main.json";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
const ListActionButton = ({ influencerAccounts, influencerAccount, setInfluencerList }) => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const cid = params.get("cid");
  const cname = params.get("cname");
  const platform = params.get("platform");
  const listId = params.get("id");
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewProfile, setViewProfile] = useState(false);
  const [viewContract, setViewContract] = useState(false);
  const [viewNoteDialog, setViewNoteDialog] = useState(false);
  const [viewUpdateDialog, setViewUpdateDialog] = useState(false);
  const [viewContactDialog, setViewContactDialog] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const arrayOfOptions = [
    {
      id: 1,
      icon: <PersonTwoTone className={styles.icon} />,
      label: influencerAccount.engagement === 0 ? "Remove Influencer" : "Suspend Influencer",
      name: "removeInfluencer",
      status: ["added directly", "contracted", "invited", "shortlisted-active", "contract_pending", "onboarded"],
    },
    {
      id: 2,
      icon: <CachedTwoTone className={styles.icon} />,
      label: "Update Profile",
      name: "updateProfile",
      status: ["added directly", "contracted", "invited", "shortlisted-active", "contract_pending", "onboarded"],
      alwaysEnabled: true,
    },
    {
      id: 3,
      icon: <ContactPageTwoTone className={styles.icon} />,
      label: "Update Contact",
      name: "updateContact",
      status: ["added directly", "contracted", "invited", "shortlisted-active", "contract_pending", "onboarded"],
    },
    {
      id: 4,
      icon: <EmailTwoTone className={styles.icon} />,
      label: "Send Invite",
      name: "sendInvite",
      status: ["shortlisted-active"],
    },
    {
      id: 5,
      icon: <LabelTwoTone className={styles.icon} />,
      label: "Add/View Note",
      name: "addviewnote",
      status: ["added directly", "contracted", "invited", "shortlisted-active", "contract_pending", "onboarded"],
    },
    {
      id: 6,
      icon: <OpenInNewTwoTone className={styles.icon} />,
      label: "View Profile",
      name: "viewprofile",
      status: ["added directly", "contracted", "invited", "shortlisted-active", "contract_pending", "onboarded"],
    },
    {
      id: 7,
      icon: <PermContactCalendarTwoTone className={styles.icon} />,
      label: "View Contract",
      name: "viewcontract",
      status: ["added directly", "contracted", "invited", "shortlisted-active", "contract_pending", "onboarded"],
    },
    {
      id: 8,
      icon: <StarBorderTwoTone className={styles.icon} />,
      label: influencerAccount.isLeader ? "Unset this Leader" : "Set as Leader",
      name: "setasleader",
      status: ["added directly", "contracted", "invited", "shortlisted-active", "contract_pending", "onboarded"],
    },
    {
      id: 9,
      icon: <CardMembershipTwoTone className={styles.icon} />,
      label: influencerAccount.certificateLink === "" ? "Generate Certificate" : "Download Certificate",
      name: "processCertificate",
      status: ["added directly", "contracted", "invited", "shortlisted-active", "contract_pending", "onboarded"],
      alwaysEnabled: true,
    },
  ];

  const invokeAction = (name) => {
    switch (name) {
      case "removeInfluencer":
        removeInfluencer();
        break;
      case "updateProfile":
        setViewUpdateDialog(true);
        break;
      case "updateContact":
        setViewContactDialog(true);
        break;
      case "sendInvite":
        inviteInfluencerToCampaign();
        break;
      case "addviewnote":
        setViewNoteDialog(true);
        break;
      case "viewprofile":
        setViewProfile(true);
        break;
      case "viewcontract":
        setViewContract(true);
        break;
      case "setasleader":
        addLeader();
        break;
      case "processCertificate":
        processCertificate(true);
        break;
      default:
        break;
    }

    handleClose();
  };

  const removeInfluencer = () => {
    dispatch(
      openSnackbar({
        message: "Please wait... We are working on it",
        severity: "info",
      })
    );
    removeInfluencerFromList([influencerAccount.id], listId).then((result) => {
      if (result) {
        const accountList = [];
        influencerAccounts.forEach((account) => {
          if (account.id !== influencerAccount.id) accountList.push(account);
        });
        dispatch(
          openSnackbar({
            message: "Influencer Removed from list successfully",
            severity: "success",
          })
        );
        setInfluencerList(accountList);
      }
    });
  };
  const inviteInfluencerToCampaign = () => {
    dispatch(
      openSnackbar({
        message: "Please wait... We are working on it",
        severity: "info",
      })
    );
    const notificationMetadata = {
      userId: JSON.parse(user.attributes.identities)[0].userId,
      sourcePlatform: PLATFORM_NAME,
      notificationTypeId: "4",
      notificationActionId: "2",
      notificationCategoryId: "5",
      generatedBy: user.attributes.name,
      priority: "medium",
      consumedBy: "gazelle",
    };
    inviteInfluencerByList(
      Number(cid),
      Number(listId),
      [influencerAccount.id],
      cname,
      platform?.toLowerCase(),
      "invite",
      notificationMetadata
    ).then((result) => {
      dispatch(
        openSnackbar({
          message: result.status,
          severity: result.error === false ? "success" : "error",
        })
      );
    });
  };

  //set an influencer as a leader
  const addLeader = () => {
    dispatch(
      openSnackbar({
        message: "Please wait... We are working on it",
        severity: "info",
      })
    );
    influencerAsLeader(listId, influencerAccount.id, !influencerAccount.isLeader).then((result) => {
      if (result && result.error === false) {
        //set the notification
        if (influencerAccount.isLeader === true) {
          dispatch(
            openSnackbar({
              message: `You have removed ${influencerAccount.name} as a leader`,
              severity: "success",
            })
          );
        } else {
          dispatch(
            openSnackbar({
              message: `You have assigned ${influencerAccount.name} as a leader`,
              severity: "success",
            })
          );
        }

        const accountList = [];
        influencerAccounts.forEach((account) => {
          if (account.id === influencerAccount.id) {
            account.isLeader = !account.isLeader;
          }
          accountList.push(account);
        });
        setInfluencerList(accountList);
      } else {
        dispatch(
          openSnackbar({
            message: `Leader already exists in this group`,
            severity: "error",
          })
        );
      }
    });
  };
  const closeViewProfileDialog = () => {
    setViewProfile(false);
  };
  const closeViewContractDialog = () => {
    setViewContract(false);
  };

  const closeViewNoteDialog = () => {
    setViewNoteDialog(false);
  };

  const closeViewUpdateDialog = () => {
    setViewUpdateDialog(false);
  };
  const closeViewContactDialog = () => {
    setViewContactDialog(false);
  };

  const setInfluencerAccountList = (list) => {
    setInfluencerList(list);
  };

  const sendInformation = (information) => {
    addNoteForInfluencer(Number(listId), influencerAccount.id, information.note, cid).then((result) => {
      if (result) {
        updateInfluencerList(result, "note", information);

        dispatch(
          openSnackbar({
            message: result.status,
            severity: result.error === false ? "success" : "error",
          })
        );
        setViewNoteDialog(false);
      }
    });
  };

  const updateInfluencerList = (result, functionality, information) => {
    if (influencerAccounts && influencerAccounts.length > 0) {
      const accountList = [];
      influencerAccounts.forEach((account) => {
        if (account.id === influencerAccount.id) {
          if (functionality === "note") {
            if (account.note === null) {
              account.note = [
                {
                  id: result.id,
                  note: information.note,
                },
              ];
            } else {
              account.note.push({
                id: result.id,
                note: information.note,
              });
            }
          }
        }
        accountList.push(account);
      });
      setInfluencerAccountList(accountList);
    }
  };

  const processCertificate = () => {
    dispatch(
      openSnackbar({
        message: "Please wait... We are working on it",
        severity: "info",
      })
    );
    if (influencerAccount.certificateLink == "") {
      const notificationMetadata = {
        userId: influencerAccount.id.toString(),
        sourcePlatform: PLATFORM_NAME,
        notificationTypeId: "4",
        notificationActionId: "1",
        notificationCategoryId: "12",
        generatedBy: user.attributes.name,
        priority: "low",
        consumedBy: "gazelle",
      };
      generateCertificate(influencerAccount.id, cname, influencerAccount.name, parseInt(listId), notificationMetadata).then(
        (result) => {
          if (result) {
            dispatch(
              openSnackbar({
                message: "Certificate has been send on influencer's  registered email address",
                severity: "success",
              })
            );
            const accountList = [];
            influencerAccounts.forEach((account) => {
              if (account.id === influencerAccount.id) {
                account.certificateLink = result.link;
              }
              accountList.push(account);
            });
            setInfluencerList(accountList);
          }
        }
      );
    } else {
      downloadCertificate(influencerAccount.certificateLink).then((result) => {
        FileSaver(result, `${cname}_${influencerAccount.name}.pdf`);
      });
    }
  };

  return (
    <>
      {viewProfile && (
        <ViewDetailedProfileDialog
          platform={platform}
          influencerAccount={influencerAccount}
          viewProfile={viewProfile}
          closeViewProfileDialog={closeViewProfileDialog}
        />
      )}
      {viewContract && (
        <ViewContractDialog
          platform={platform}
          influencerAccount={influencerAccount}
          viewContract={viewContract}
          cid={cid}
          closeViewContractDialog={closeViewContractDialog}
        />
      )}
      {viewNoteDialog && (
        <ViewNoteDialog
          functionality={"note"}
          influencerAccount={influencerAccount}
          viewNoteDialog={viewNoteDialog}
          closeViewNoteDialog={closeViewNoteDialog}
          sendInformation={sendInformation}
        />
      )}
      {viewUpdateDialog && (
        <ViewUpdateDialog
          influencerAccounts={influencerAccounts}
          platform={platform}
          influencerAccount={influencerAccount}
          viewUpdateDialog={viewUpdateDialog}
          closeViewUpdateDialog={closeViewUpdateDialog}
          setInfluencerAccountList={setInfluencerAccountList}
        />
      )}
      {viewContactDialog && (
        <ViewContactDialog
          influencerAccounts={influencerAccounts}
          platform={platform}
          influencerAccount={influencerAccount}
          viewContactDialog={viewContactDialog}
          closeViewUpdateDialog={closeViewContactDialog}
          setInfluencerAccountList={setInfluencerAccountList}
        />
      )}
      <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
        <MoreVertOutlined />
      </IconButton>
      <Box style={{ borderRadius: "30%" }}>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose} className={styles.menuitem}>
          {arrayOfOptions.map((value) => (
            <div>
              {value.status.includes(influencerAccount.status.toLowerCase()) && (
                <>
                  <MenuItem key={value.id} onClick={() => invokeAction(value.name)}>
                    {value.icon}&nbsp;&nbsp;&nbsp;&nbsp;{value.label}
                  </MenuItem>
                  {value.id < arrayOfOptions.length && <Divider key={value.id} sx={{ my: 0.5 }} />}
                </>
              )}
            </div>
          ))}
        </Menu>
      </Box>
    </>
  );
};

ListActionButton.propTypes = {
  influencerAccounts: array.isRequired,
  influencerAccount: object.isRequired,
  setInfluencerList: func.isRequired,
};

export default ListActionButton;
