import * as React from "react";
import {
  Stack,
  Button,
  Typography,
  DialogTitle,
  IconButton,
  Dialog,
  DialogContent,
  InputLabel,
  FormControl,
  TextareaAutosize,
  TextField,
  Grid,
  Box,
  DialogActions,
  Backdrop,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
// import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import {
  snapshotHeaderIcons,
  textColor,
} from "../../components/campaignAnalysis/snapshot";
import { getCampList } from "../../services/list/ListDetails";
import StyledTable from "../../utils/styledTable";
import FetchingDetailsLoader from "../../components/general/FetchingDetailsLoader";
import utils from "../../utils/utils";
import CampaignActions from "./CampaignActions";
import { openSnackbar } from "../../redux/features/snackbar/snackbarSlice";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Select, MenuItem } from "@material-ui/core";
import OutlinedInput from "@mui/material/OutlinedInput";
import { getCampaignListData } from "../../services/campaign/CampaignDetails";
import ReactTagInput from "@pathofdev/react-tag-input";
import { Country, State, City } from "country-state-city";
import { API_HOST, DOS, DONTS, TEMPLATE, FAQ } from "../../config/main.json";
import FAQS from "react-faqs-component";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import axios from "axios";
import CustomTabs from "../../common/CustomTabs/CustomTabs";
import campaignTabsArray from "../../utils/campaignTabsArray";
import InputAdornment from "@mui/material/InputAdornment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    width: "2500px", // Adjust the width as needed
  },
}));

let tabArray;
const ListAnalysis = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [campaignId, setCampaignId] = useState(0);
  const [companyId, setCompanyId] = useState("");
  // const [disableRefreshButton, setDisableRefreshButton] = useState(false);
  const [campaignDetails, setCampaignDetails] = useState({});

  const [anchor] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // defines that modal for creating list will be open or not
  const [stepMessage, setStepMessage] = useState("Basic Details"); // defines the title in the process of creating a list
  const [campaignList, setCampaignList] = useState([]); // list of compaigns that is display for" Campaign Name" while creating new list
  const [companyList, setCompanyList] = useState([]); //list of all active accounts that is display for Accounts field in the modal of new list
  const [platformId, setPlatformId] = useState("");
  const [newListName, setNewListName] = useState(""); // name of the list, which we are creating
  const [description, setDescription] = useState("");
  const [tags, setTags] = useState([]);
  const [notes, setNotes] = useState("");
  const [timezoneList, setTimezoneList] = useState([]);
  const [timezoneLable, setTimezoneLable] = useState(0);
  const [timezone, setTimezone] = useState(0);
  const [errorAlert, setErrorAlert] = useState(
    "Please complete all required(*) fields."
  );
  const [currentStep, setCurrentStep] = useState("1"); // defines the step no in the process of creating a list
  const [minFollowers, setMinFollowers] = useState(0);
  const [maxFollowers, setMaxFollowers] = useState(0);
  const [geoType, setGeoType] = useState(2);
  const [country, setCountry] = useState(""); // country code
  const [state, setState] = useState(""); // state code
  const [cityList, setCityList] = useState([]); // contains detailed list of all the city of a specific/selectrd state
  const [displayGeo, setDisplayGeo] = useState("flex");
  const [countryList, setCountryList] = useState([]); // contains detailed list of all the country
  const [stateLable, setStateLable] = useState(""); // contains details of a specific/selected state
  const [city, setCity] = useState(""); // city name
  const [cityLable, setCityLable] = useState(""); // contains details of a specific/selected city
  const [stateList, setStateList] = useState([]); // contains detailed list of all the state of a specific/selectrd country
  const [countryLable, setCountryLable] = useState(""); // contains details of a specific/selected country
  const [currency, setCurrency] = useState(""); // currancy selected while creating new list
  const [infBudget, setInfBudget] = useState(0); // budget selected while creating new list
  const [displayPT, setDisplayPT] = useState("none"); // defines that display of "No Of Days" firld is "flex" or "none"
  const [displayDT, setDisplayDT] = useState("none"); // defines that display of "Payment date" firld is "flex" or "none"
  const [required, setRequired] = useState(false);
  const [paymentTerm, setPaymentTerm] = useState(""); // type of payment term (num) while creating new list
  const [paymentDate, setPaymentDate] = useState(""); // date of payment selected while creating new list
  const [noofDays, setNoOfDays] = useState(0); // no of days for payment selected while creating new list
  const [dos, setDos] = useState(DOS); // coniatins the dos statements while creating new list
  const [donts, setDonts] = useState(DONTS); // coniatins the donts statements while creating new list
  const open = Boolean(anchor);
  const [faqmodalOpen, setFaqmodalOpen] = useState(false); //  defines that modal of "All FAQ" is be open or not
  const [faqListData, setFaqListData] = useState(FAQ);
  const contentBlock = htmlToDraft(TEMPLATE);
  const [counter, setCounter] = useState(1); // defines no of "Q & A" rows are there in FAQs section of create list
  const [faqQuestion, setFaqQuestion] = useState({}); // contains all the questions from FAQs section of create list
  const [faqAnswer, setFaqAnswer] = useState({}); // contains all the answers from FAQs section of create list
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromBlockArray(contentBlock.contentBlocks)
    )
  );
  const [convertedContent, setConvertedContent] = useState(""); // contains content of sample post section of create list
  const [listStatus, setListStatus] = useState(2);
  const [disableNext, setDisableNext] = useState(false);
  const [listNameCount, setListNameCount] = useState(0);

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const campId = parseInt(params.get("id"));
  tabArray = campaignTabsArray(campId);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [hideAddListModalContent, setHideAddListModalContent] = useState(false);

  const dispatch = useDispatch();
  const borderRadius = "10px";
  const initialOrder = "name";
  const colors = {
    header: {
      backgroundColor: "#F3F6F9",
      textColor: "#464E5F",
    },
    body: {
      textColor: "#464E5F",
    },
  };
  const tableRowHeaders = [
    {
      id: 1,
      label: `List Name`,
      field: "name",
      alignment: "left",
      numeric: false,
      percentage: false,
    },
    {
      id: 2,
      label: "No. of Influencers in list",
      field: "influencers",
      alignment: "center",
      numeric: false,
      percentage: false,
      sortable: false,
    },
    {
      id: 3,
      label: "Status",
      field: "status",
      alignment: "center",
      numeric: false,
      percentage: false,
    },
  ];
  const hideOnExport = ["save-engagement-analysis", "save-phase-analysis"];

  useEffect(() => {
    setCampaignId(campId);

    setLoading(true);
    getCampaignDetails(campId);

    utils.getCompanyData().then((data) => {
      //get details of active accounts
      setCompanyList(data);
    });

    getCampaignList();

    utils.getTimezoneData("all").then((data) => {
      const timezoneData = data;
      setTimezoneList(timezoneData);
    });

    setCountryList(Country.getAllCountries());
  }, []);

  const getCampaignList = async () => {
    const data = await getCampaignListData();
    const campaignList = data;
    setCampaignList(campaignList);
  };

  const getCampaignDetails = (campId) => {
    utils.getCampaignData(campId).then((data) => {
      setCompanyId(data.companyId);
      setCampaignDetails(data);
      getPostList(campId, data);
    });
  };

  const createNewList = (event) => {
    clearState();
    setModalOpen(true);
  };

  const handleDialogClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    if (hasUnsavedChanges) {
      setConfirmationOpen(true);
      setHideAddListModalContent(true);
      return;
    }
    clearState();
    setModalOpen(false);
  };

  const handleCloseIconClick = (event) => {
    if (hasUnsavedChanges) {
      setConfirmationOpen(true);
      setHideAddListModalContent(true);
      return;
    }
    clearState();
    setModalOpen(false);
  };

  useEffect(() => {
    const isAnyFieldNonEmpty =
      newListName ||
      description ||
      platformId ||
      tags.length > 0 ||
      notes ||
      minFollowers ||
      maxFollowers ||
      country ||
      state ||
      city ||
      currency ||
      infBudget ||
      paymentTerm ||
      noofDays ||
      paymentDate ||
      dos !== DOS ||
      donts !== DONTS ||
      Object.keys(faqQuestion).length > 0 ||
      Object.keys(faqAnswer).length > 0 ||
      editorState.getCurrentContent().hasText();

    setHasUnsavedChanges(isAnyFieldNonEmpty);
  }, [
    newListName,
    description,
    platformId,
    tags,
    notes,
    minFollowers,
    maxFollowers,
    country,
    state,
    city,
    currency,
    infBudget,
    paymentTerm,
    noofDays,
    paymentDate,
    dos,
    donts,
    faqQuestion,
    faqAnswer,
    editorState,
  ]);

  const handleConfirmationCancel = () => {
    setConfirmationOpen(false);
  };

  const handleConfirmationContinue = () => {
    setConfirmationOpen(false);
    clearState();
    setModalOpen(false);
  };

  const clearState = () => {
    setHasUnsavedChanges(false);
    setCurrentStep(1);
    setListStatus(1);
    setCounter(1);
    setFaqQuestion({});
    setFaqAnswer({});
    // setFaqListData(tmpFaq)
    // setCompanyId("");
    //setCampaignId("");
    setPlatformId("");
    setNewListName("");
    setNotes("");
    setDescription("");
    setTags([]);
    setMinFollowers(0);
    setMaxFollowers(0);
    setGeoType(2);
    setCurrency("");
    setInfBudget(0);
    setPaymentTerm("");
    setTimezoneLable(0);
    setTimezone(0);
    setDos(DOS);
    setDonts(DONTS);
    const contentBlock = htmlToDraft(TEMPLATE);
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlock.contentBlocks)
      )
    );
    setConvertedContent("");
    setDisplayGeo("flex");
    setCountryLable("");
    setCountry("");
    setStateList([]);
    setStateLable("");
    setState("");
    setCityList([]);
    setCityLable("");
    setCity("");
    setDisplayPT("none");
    setNoOfDays(0);
    setDisplayDT("none");
    setPaymentDate("");
  };

  const handleChangeAccount = (event) => {
    const cid = event.currentTarget.dataset.value;
    setCompanyId(cid);
    utils.getCampaignDataById(cid).then((data) => {
      // give details of those campaigns that have utilized the given/common account
      var campaignList = data;
      if (campaignList == null) {
        var newCampaign = {};
        newCampaign["id"] = 0;
        newCampaign["name"] = "Account-Campaign Mapping Not Available";
        setErrorAlert(
          "There is no campaign associated with this account, Please create campaign first to create a list."
        );
        campaignList = [];
        campaignList.push(newCampaign);
      } else {
        setErrorAlert("Please complete all required(*) fields.");
      }
      setCampaignList(campaignList);
    });
  };

  const handleChangeCampaign = (event) => {
    const cid = event.currentTarget.dataset.value;
    setCampaignId(cid);
  };

  const handleChangePlatform = (event) => {
    const pid = event.currentTarget.dataset.value;
    setPlatformId(pid);
  };

  const handleInputChange = (event) => {
    setHasUnsavedChanges(true);
    let val = event.target.value;
    setListNameCount(val.length);
    if (val.length > 24) {
      if (!disableNext) {
        setDisableNext(true);
      }
    } else {
      if (disableNext) {
        setDisableNext(false);
      }
    }
    setNewListName(event.target.value);
  };

  const handleDescChange = (event) => {
    event.preventDefault();
    setDescription(event.target.value);
  };

  const handleNotesChange = (event) => {
    event.preventDefault();
    setNotes(event.target.value);
  };

  const handleTimezoneChange = (event) => {
    setTimezone("");
    if (event !== null) {
      setTimezone(parseInt(event.id));
    }
  };

  const handleMinFollowersChange = (event) => {
    event.preventDefault();
    setMinFollowers(event.target.value);
  };

  const handleMaxFollowersChange = (event) => {
    event.preventDefault();
    setMaxFollowers(event.target.value);
  };

  const handleGeoTypeChange = (event) => {
    event.preventDefault();
    setGeoType(event.target.value);
    if (event.target.value === 1) {
      setCountry("");
      setState("");
      setCityList("");
      setDisplayGeo("none");
    } else {
      setDisplayGeo("flex");
    }
  };

  const getCity = (array, value) => {
    const result = array.find(({ name }) => name === value);
    return result;
  };
  const getCountry = (array, value) => {
    const result = array.find(({ isoCode }) => isoCode === value);
    return result;
  };
  const getState = (array, value) => {
    const result = array.find(({ isoCode }) => isoCode === value);
    return result;
  };

  const handleCountryChange = (event, value) => {
    if (event === null) {
      setState("");
      setStateLable(null);
      setCityList([]);
      setCity("");
      setCityLable(null);
      return;
    }
    var countryCode = event.isoCode;
    setStateList(State.getStatesOfCountry(countryCode));
    setCountry(countryCode);
    setCountryLable(getCountry(countryList, countryCode));
    setDisplayGeo("flex");
  };

  const handleStateChange = (event) => {
    if (event === null) {
      setState("");
      setStateLable(null);
      setCityList([]);
      setCity("");
      setCityLable(null);
      return;
    }
    var countryCode = event.countryCode;
    var stateCode = event.isoCode;
    setCityList(City.getCitiesOfState(countryCode, stateCode));
    setStateLable(getState(stateList, stateCode));
    setState(stateCode);
  };

  const handleCityChange = (event) => {
    if (event === null) {
      setCity("");
      setCityLable(null);
      return;
    }
    var city = event.name;
    setCity(city);
    setCityLable(getCity(cityList, event.name));
  };

  const handleCurrencyChange = (event) => {
    event.preventDefault();
    setCurrency(event.target.value);
  };

  const handleInfBudgetChange = (event) => {
    event.preventDefault();
    setInfBudget(event.target.value);
  };

  const handlePaymentChange = (event) => {
    event.preventDefault();
    var paymentTerm = event.target.value;
    setDisplayPT("none");
    setDisplayDT("none");
    if (parseInt(paymentTerm) === 4) {
      setDisplayPT("flex");
      setDisplayDT("none");
      setRequired(false);
    } else if (parseInt(paymentTerm) === 5) {
      setDisplayPT("none");
      setDisplayDT("flex");
      setRequired(true);
    }
    setPaymentTerm(event.target.value);
  };

  const handleNoOfDaysChange = (event) => {
    event.preventDefault();
    setPaymentDate("");
    setNoOfDays(event.target.value);
  };

  const handlePaymentDateChange = (event) => {
    event.preventDefault();
    setNoOfDays(0);
    setPaymentDate(event.target.value);
  };

  const handleKeyUp = (id, event) => {
    var keycode = event.keyCode ? event.keyCode : event.which;
    if (keycode == "13") {
      document.getElementById(id).value += "• ";
    }
    var txtval = document.getElementById(id).value;
    if (txtval.substr(txtval.length - 1) === "\n") {
      document.getElementById(id).value = txtval.substring(
        0,
        txtval.length - 1
      );
    }
    if (id === "dos") {
      setDos(document.getElementById(id).value);
    } else {
      setDonts(document.getElementById(id).value);
    }
  };

  const faqList = (event) => {
    setFaqmodalOpen(true);
  };

  const handleFaqClose = () => {
    setFaqmodalOpen(false);
  };

  const handleCloseFQA = () => {
    setCounter(counter + 1);
  };

  const handleQuestionOnChange = (index) => (e) => {
    const abc = {};
    abc[index] = e.target.value;
    setFaqQuestion({ ...faqQuestion, ...abc });
  };

  const handleAnswerOnChange = (index) => (e) => {
    const abc = {};
    abc[index] = e.target.value;
    setFaqAnswer({ ...faqAnswer, ...abc });
  };

  const handleRemoveClick = (index) => (e) => {
    let tmpFaqQ = {};
    let tmpFaqQnew = {};
    let tmpFaqAnew = {};
    tmpFaqQ = faqQuestion;
    let tmpFaqA = faqAnswer;
    delete tmpFaqQ[index];
    delete tmpFaqA[index];
    var i = 0;
    for (var j in tmpFaqQ) {
      tmpFaqQnew[i] = tmpFaqQ[j];
      tmpFaqAnew[i] = tmpFaqA[j];
      i++;
    }
    setFaqQuestion(tmpFaqQnew);
    setFaqAnswer(tmpFaqAnew);
    var count = counter - 1;
    setCounter(count);
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    setConvertedContent(
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
  };

  const draftButton = (event) => {
    return (
      <FormControl
        style={{ marginBottom: "10px", float: "right", margin: "18px" }}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={(event) => draft(event)}
          size="large"
        >
          Save as Draft
        </Button>
      </FormControl>
    );
  };

  const draft = (event) => {
    setListStatus(6);
    newListClick(event);
  };

  const previousButton = (event) => {
    let currentStepInner = currentStep;
    if (parseInt(currentStepInner) !== 1) {
      return (
        <FormControl
          style={{ marginBottom: "10px", float: "right", margin: "18px" }}
        >
          <Button variant="outlined" onClick={prev} size="large">
            Previous
          </Button>
        </FormControl>
      );
    }
    return null;
  };

  const prev = (event) => {
    if (parseInt(currentStep) === 2) {
      setStepMessage("Basic Details");
    } else if (parseInt(currentStep) === 3) {
      setStepMessage("Targeting Details");
    } else if (parseInt(currentStep) === 4) {
      setStepMessage("Payment Details");
    } else if (parseInt(currentStep) === 5) {
      setStepMessage("Guidlines");
    } else if (parseInt(currentStep) === 6) {
      setStepMessage("Faq's");
    }
    let currentStepInner = currentStep;
    currentStepInner =
      currentStepInner <= 1 ? 1 : parseInt(currentStepInner) - 1;
    setCurrentStep(currentStepInner);
  };

  const nextButton = (event) => {
    let currentStepInner = currentStep;
    if (currentStepInner < 6) {
      return (
        <FormControl
          style={{ marginBottom: "10px", float: "right", margin: "20px" }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={next}
            size="large"
            disabled={disableNext}
          >
            Next
          </Button>
        </FormControl>
      );
    }
    return null;
  };

  const next = (event) => {
    if (!validateForm(currentStep)) return false;
    if (parseInt(currentStep) === 1) {
      setStepMessage("Targeting Details");
    } else if (parseInt(currentStep) === 2) {
      setStepMessage("Payment Details");
    } else if (parseInt(currentStep) === 3) {
      setStepMessage("Guidlines");
    } else if (parseInt(currentStep) === 4) {
      setStepMessage("Faq's");
    } else if (parseInt(currentStep) === 5) {
      setStepMessage("Sample Post");
    }
    let currentStepInner = currentStep;
    currentStepInner =
      currentStepInner >= 5 ? 6 : parseInt(currentStepInner) + 1;
    setCurrentStep(currentStepInner);
  };

  const validateForm = (step) => {
    // This function deals with validation of the form fields
    var valid = true;
    if (parseInt(step) === 1) {
      if (
        companyId === "" ||
        campaignId === "" ||
        campaignId == 0 ||
        platformId === "" ||
        newListName === ""
      ) {
        dispatch(
          openSnackbar({
            message: errorAlert,
            severity: "error",
          })
        );
        valid = false;
      }
    } else if (parseInt(step) === 2) {
      if (country === "") {
        dispatch(
          openSnackbar({
            message: errorAlert,
            severity: "error",
          })
        );
        valid = false;
      }
    }
    return valid; // return the valid status
  };

  const submitButton = (event) => {
    let currentStepInner = currentStep;
    if (currentStepInner === 6) {
      return (
        <FormControl
          style={{ marginBottom: "10px", float: "right", margin: "20px" }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={(event) => newListClick(event)}
            size="large"
          >
            Submit
          </Button>
        </FormControl>
      );
    }
    return null;
  };

  // creates a new list
  const newListClick = (event) => {
    var optionAxios = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    var endPoint = "/list/add";

    const payload = {
      name: newListName,
      aid: companyId,
      cid: parseInt(campaignId, 10),
      platform: parseInt(platformId, 10),
      description: description,
      tags: tags.join(" , "),
      notes: notes,
      tz: timezone,
      geoType: 2,
      country: country,
      state: state,
      city: city,
      currency: currency,
      infBudget: infBudget,
      paymentTerm: paymentTerm,
      minFollowers: parseInt(minFollowers),
      maxFollowers: parseInt(maxFollowers),
      noOfDays: parseInt(noofDays),
      paymentDate: paymentDate,
      postContent: convertedContent,
      dos: dos,
      donts: donts,
      status: listStatus,
      addedFrom: "oryx",
    };
    var faqData = [];
    Object.keys(faqQuestion).forEach((c) => {
      var obj = {
        question: faqQuestion[c],
        answer: faqAnswer[c],
      };
      faqData.push(obj);
    });
    payload["faqs"] = JSON.stringify(faqData);
    axios.post(API_HOST + endPoint, payload, optionAxios).then((res) => {
      if (res["data"]["status"] !== "success") {
        dispatch(
          openSnackbar({
            message: "Could not create",
            severity: "error",
          })
        );
      }
      setModalOpen(false);
      dispatch(
        openSnackbar({
          message: `List ${newListName} has been created successfully!`,
          severity: "success",
        })
      );
      getCampaignDetails(campaignId);
    });
    event.preventDefault();
    return false;
  };

  const getPostList = async (campId, campaignDetails) => {
    const postData = await getCampList(campId);
    const columns = [];
    let listHref = "#";
    postData?.forEach((row, index) => {
      listHref =
        "/list/details?id=" +
        row.id +
        "&name=" +
        row.name +
        "&cid=" +
        campId +
        "&cname=" +
        campaignDetails["name"] +
        "&platform=" +
        row.platform +
        "&aname=" +
        row.aname +
        "&type=unarchived";
      var object = {
        id: row.id,
        name: (
          <div>
            <Typography sx={textColor[row.platform]}>
              {snapshotHeaderIcons[row.platform]}
              &nbsp;&nbsp;
              <span
                style={{
                  color: "black",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "14px",
                }}
              >
                <a href={listHref} target="_blank" rel="noreferrer">
                  {" "}
                  {row.name}
                  <span style={{ visibility: "hidden" }}>{row.id}</span>
                </a>
              </span>
            </Typography>
          </div>
        ),
        influencers: (
          <div>
            {row.postedCount} of {row.count} Posted
          </div>
        ),
        status: row.status,
      };
      columns.push(object);
    });
    setPosts(columns);
    setLoading(false);
  };

  // const refreshData = () => {
  //   dispatch(
  //     openSnackbar({
  //       message: "Data refresh has been initiated!",
  //       severity: "info",
  //     })
  //   );
  //   setDisableRefreshButton(true);
  //   utils.refreshAnalyticsData(campaignId).then((data) => {
  //     if (data.Msg === "success") {
  //       dispatch(
  //         openSnackbar({
  //           message: "Data refresh successfully started!",
  //           severity: "success",
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         openSnackbar({
  //           message: data.Msg,
  //           severity: "error",
  //         })
  //       );
  //     }
  //   });
  // };

  return (
    <>
      <CampaignActions name={campaignDetails.name} id={campaignId} />
      <br />
      <CustomTabs tabArray={tabArray} selectedTab={"3"} />

      <Stack direction="row" justifyContent="end">
        {/* <Button
          sx={{ marginRight: "30px", marginBottom: "30px", marginTop: "10px" }}
          variant="contained"
          onClick={refreshData}
          disabled={disableRefreshButton}
          startIcon={<RefreshIcon />}
        >
          Refresh
        </Button> */}
        <Button
          sx={{ marginRight: "30px", marginBottom: "30px", marginTop: "10px" }}
          variant="contained"
          id="demo-customized-button"
          startIcon={<AddIcon />}
          onClick={createNewList}
        >
          Create A List
        </Button>
      </Stack>

      {loading ? (
        <FetchingDetailsLoader />
      ) : (
        <>
          {console.log(posts)}
          <StyledTable
            headerColumns={tableRowHeaders}
            bodyColumns={posts}
            borderRadius={borderRadius}
            colors={colors}
            initialOrder={initialOrder}
            columnAlignment={"center"}
            checkbox={false}
          />
        </>
      )}

      {/* handles create a list */}
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        open={modalOpen && !confirmationOpen}
        onClose={handleDialogClose}
        closeAfterTransition
        fullWidth
        maxWidth="md"
        disableEscapeKeyDown
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <h2>Add List - {stepMessage}</h2>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseIconClick}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <div
            id="modal-add_influencer_to_list-body"
            style={{ padding: "10px" }}
          >
            <Grid item xs={12}>
              <Box>
                <React.Fragment>
                  <form id="stepForm" onSubmit={newListClick}>
                    {currentStep == 1 && (
                      <div>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ display: "flex", maxWidth: "100%" }}
                        >
                          <FormControl
                            style={{
                              width: "220px",
                              marginBottom: "15px",
                              flex: 1,
                              marginRight: "20px",
                            }}
                          >
                            <InputLabel
                              id="account-selection-label"
                              InputLabelProps={{ shrink: true }}
                            >
                              Account *
                            </InputLabel>
                            <Select
                              disabled
                              required={true}
                              label="Account *"
                              id="companyId"
                              InputLabelProps={{ shrink: true }}
                              value={companyId}
                              onChange={handleChangeAccount}
                              input={
                                <OutlinedInput
                                  label="Account *"
                                  id="demo-dialog-native"
                                />
                              }
                            >
                              {companyList.map((aname) => (
                                <MenuItem
                                  value={aname.id}
                                  key={aname.id}
                                  style={{ height: "50px" }}
                                >
                                  {aname.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl
                            style={{
                              width: "220px",
                              marginBottom: "15px",
                              flex: 1,
                            }}
                          >
                            <InputLabel
                              id="account-selection-label"
                              InputLabelProps={{ shrink: true }}
                            >
                              Campaign Name *
                            </InputLabel>
                            <Select
                              disabled
                              required={true}
                              label="Campaign Name *"
                              InputLabelProps={{ shrink: false }}
                              value={campaignId}
                              id="campaignId"
                              onChange={handleChangeCampaign}
                              refs="campaignId"
                              input={
                                <OutlinedInput
                                  label="Campaign Name *"
                                  id="demo-dialog-native"
                                />
                              }
                            >
                              {campaignList?.map((cname) => (
                                <MenuItem value={cname.id} key={cname.id}>
                                  {cname.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ display: "flex", maxWidth: "100%" }}
                        >
                          <FormControl
                            style={{
                              width: "220px",
                              marginBottom: "15px",
                              flex: 1,
                              marginRight: "20px",
                            }}
                          >
                            <InputLabel
                              id="account-selection-label"
                              InputLabelProps={{ shrink: true }}
                            >
                              Platform *
                            </InputLabel>
                            <Select
                              required={true}
                              label="Platform *"
                              InputLabelProps={{ shrink: false }}
                              id="platformId"
                              value={platformId}
                              onChange={handleChangePlatform}
                              refs="platformId"
                              input={
                                <OutlinedInput
                                  label="Platform *"
                                  id="demo-dialog-native"
                                />
                              }
                            >
                              <MenuItem value={1} key={1}>
                                Instagram
                              </MenuItem>
                              <MenuItem value={2} key={2}>
                                Twitter
                              </MenuItem>
                              <MenuItem value={3} key={3}>
                                TikTok
                              </MenuItem>
                              <MenuItem value={4} key={4}>
                                YouTube
                              </MenuItem>
                              <MenuItem value={5} key={5}>
                                LinkedIn
                              </MenuItem>
                              <MenuItem value={6} key={6}>
                                Facebook
                              </MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl
                            style={{
                              width: "220px",
                              marginBottom: "15px",
                              flex: 1,
                            }}
                          >
                            <TextField
                              variant="outlined"
                              id="newListName"
                              required={true}
                              refs="newListName"
                              label="List Name"
                              value={newListName}
                              onChange={handleInputChange}
                              helperText={
                                listNameCount <= 24 ? (
                                  <div style={{ marginTop: -5 }}>
                                    list Name can have maximum 24 characters
                                  </div>
                                ) : (
                                  <div style={{ color: "red", marginTop: -5 }}>
                                    You've exceeded the 24 character limit for
                                    the list name.
                                  </div>
                                )
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <span style={{ fontSize: 12 }}>
                                      {listNameCount} /25
                                    </span>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ display: "flex", maxWidth: "100%" }}
                        >
                          <FormControl
                            style={{
                              width: "220px",
                              marginBottom: "15px",
                              flex: 1,
                              marginRight: "20px",
                            }}
                          >
                            <TextField
                              variant="outlined"
                              required={false}
                              type="text"
                              value={description}
                              id="description"
                              refs="description"
                              name="description"
                              label="Description"
                              onChange={handleDescChange}
                            />
                          </FormControl>
                          <FormControl
                            style={{
                              width: "220px",
                              marginBottom: "15px",
                              flex: 1,
                            }}
                          >
                            <ReactTagInput
                              variant="outlined"
                              sx={{ height: "1.4375em" }}
                              tags={tags}
                              placeholder="Tags"
                              refs="tags"
                              name="tags"
                              label="Tags"
                              maxTags={3}
                              onChange={(newTags) => setTags(newTags)}
                            />
                          </FormControl>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ display: "flex", maxWidth: "100%" }}
                        >
                          <FormControl
                            style={{
                              width: "220px",
                              marginBottom: "15px",
                              flex: 1,
                              marginRight: "20px",
                            }}
                          >
                            <TextareaAutosize
                              variant="outlined"
                              minRows={2}
                              required={false}
                              refs="note"
                              placeholder="Notes"
                              value={notes}
                              onChange={handleNotesChange}
                              style={{
                                height: 56,
                                borderRadius: "4px",
                                borderColor: "#c4c4c4",
                              }}
                            />
                          </FormControl>

                          <FormControl
                            style={{
                              width: "220px",
                              marginBottom: "15px",
                              flex: 1,
                            }}
                          >
                            <Autocomplete
                              options={timezoneList}
                              getOptionLabel={(timezone) => timezone.text}
                              defaultValue={timezoneLable || timezoneLable[0]}
                              onChange={(event, value) =>
                                handleTimezoneChange(value)
                              }
                              id="timezone-data"
                              renderInput={(params) => (
                                <TextField {...params} label="Timezone" />
                              )}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}></Grid>
                      </div>
                    )}
                    {currentStep == 2 && (
                      <div>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ display: "flex", maxWidth: "100%" }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <TextField
                                  variant="outlined"
                                  refs="minfollowers"
                                  label="Min Followers"
                                  type="number"
                                  value={minFollowers}
                                  onChange={handleMinFollowersChange}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <TextField
                                  variant="outlined"
                                  required={false}
                                  refs="maxfollowers"
                                  label="Max Followers"
                                  type="number"
                                  value={maxFollowers}
                                  onChange={handleMaxFollowersChange}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <br />
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ display: "flex", maxWidth: "100%" }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-standard-label">
                                  Geo Target
                                </InputLabel>
                                <Select
                                  required={false}
                                  label="Geo Target"
                                  id="geoTypeId"
                                  value={geoType}
                                  onChange={handleGeoTypeChange}
                                  refs="geoTypeId"
                                  input={
                                    <OutlinedInput
                                      label="Geo Target"
                                      id="demo-dialog-native"
                                    />
                                  }
                                  disabled
                                >
                                  <MenuItem value={1} key={1}>
                                    Gobal
                                  </MenuItem>
                                  <MenuItem value={2} key={2}>
                                    Specific
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <Autocomplete
                                  options={countryList}
                                  required={true}
                                  getOptionLabel={(country) => country.name}
                                  defaultValue={countryLable || ""}
                                  value={countryLable || null}
                                  onChange={(event, value) =>
                                    handleCountryChange(value)
                                  }
                                  id="country-data"
                                  renderInput={(params) => (
                                    <TextField {...params} label="Country *" />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <br />
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ display: "flex", maxWidth: "100%" }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <Autocomplete
                                  options={stateList}
                                  getOptionLabel={(state) => state.name}
                                  defaultValue={stateLable || ""}
                                  value={stateLable || null}
                                  onChange={(event, value) =>
                                    handleStateChange(value)
                                  }
                                  id="state-data"
                                  renderInput={(params) => (
                                    <TextField {...params} label="State" />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                              <FormControl fullWidth>
                                <Autocomplete
                                  options={cityList}
                                  getOptionLabel={(city) => city.name}
                                  defaultValue={cityLable || ""}
                                  value={cityLable || null}
                                  onChange={(event, value) =>
                                    handleCityChange(value)
                                  }
                                  id="state-data"
                                  renderInput={(params) => (
                                    <TextField {...params} label="City" />
                                  )}
                                />
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3}></Grid>
                      </div>
                    )}
                    {currentStep == 3 && (
                      <div>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ display: "flex", maxWidth: "100%" }}
                        >
                          <FormControl
                            style={{
                              width: "220px",
                              marginBottom: "15px",
                              flex: 1,
                              marginRight: "20px",
                            }}
                          >
                            <InputLabel id="demo-simple-select-standard-label">
                              Currency
                            </InputLabel>
                            <Select
                              label="Currency"
                              id="currencyId"
                              value={currency}
                              onChange={handleCurrencyChange}
                              refs="currencyId"
                              input={
                                <OutlinedInput
                                  label="Currency"
                                  id="demo-dialog-native"
                                />
                              }
                            >
                              <MenuItem value="$">US Dollar ($)</MenuItem>
                              <MenuItem value="£">British Pound (£)</MenuItem>
                              <MenuItem value="€">Euro (€)</MenuItem>
                              <MenuItem value="KSh">
                                Kenyan Shilling (KSh){" "}
                              </MenuItem>
                              <MenuItem value="R">
                                South African Rand (R){" "}
                              </MenuItem>
                              <MenuItem value="₦">Nigerian Naira (₦)</MenuItem>
                              <MenuItem value="₹">Indian Rupee (₹)</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl
                            style={{
                              width: "220px",
                              marginBottom: "15px",
                              flex: 1,
                            }}
                          >
                            <TextField
                              variant="outlined"
                              required={false}
                              refs="bugdet"
                              label="Per Influencer Budget"
                              type="number"
                              value={infBudget}
                              onChange={handleInfBudgetChange}
                            />
                          </FormControl>
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ display: "flex", width: "100%" }}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            style={{ display: "flex", width: "50%" }}
                          >
                            <FormControl
                              style={{
                                width: "100%",
                                marginBottom: "15px",
                                flex: 1,
                                marginRight: "10px",
                              }}
                            >
                              <InputLabel id="demo-simple-select-standard-label">
                                Payment Term
                              </InputLabel>
                              <Select
                                required={false}
                                label="Payment Term"
                                id="paymentId"
                                value={paymentTerm}
                                onChange={handlePaymentChange}
                                refs="paymentId"
                                input={
                                  <OutlinedInput
                                    label="Payment Term"
                                    id="demo-dialog-native"
                                  />
                                }
                              >
                                <MenuItem value={1} key={1}>
                                  Weekly
                                </MenuItem>
                                <MenuItem value={2} key={2}>
                                  Bi-Weekly/Fortnightly
                                </MenuItem>
                                <MenuItem value={3} key={3}>
                                  Monthly
                                </MenuItem>
                                <MenuItem value={4} key={4}>
                                  No. Days after Task Completion
                                </MenuItem>
                                <MenuItem value={5} key={5}>
                                  Particular Payment Date (s) - On a particular
                                  day
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid
                            id="noofDays"
                            item
                            xs={12}
                            sm={6}
                            style={{
                              maxWidth: "50%",
                              flex: 1,
                              display: displayPT,
                            }}
                          >
                            <FormControl
                              style={{
                                width: "220px",
                                marginBottom: "15px",
                                flex: 1,
                                marginLeft: "10px",
                              }}
                            >
                              <TextField
                                variant="outlined"
                                required={true}
                                refs="noofDays"
                                label="No Of Days"
                                type="number"
                                value={noofDays}
                                onChange={handleNoOfDaysChange}
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            id="onDate"
                            item
                            xs={12}
                            sm={6}
                            style={{
                              maxWidth: "50%",
                              flex: 1,
                              display: displayDT,
                            }}
                          >
                            <FormControl
                              style={{
                                width: "220px",
                                marginBottom: "15px",
                                flex: 1,
                                marginLeft: "10px",
                              }}
                            >
                              <TextField
                                clearable
                                value={paymentDate}
                                id="paymentDate"
                                variant="outlined"
                                name="paymentDate"
                                label="Payment Date"
                                InputLabelProps={{
                                  shrink: true,
                                  required: true,
                                }}
                                type="date"
                                required={required}
                                onChange={handlePaymentDateChange}
                                sx={{ width: "100%" }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3}></Grid>
                      </div>
                    )}
                    {currentStep == 4 && (
                      <div>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ display: "flex", maxWidth: "100%" }}
                        >
                          <FormControl
                            style={{
                              width: "220px",
                              marginBottom: "15px",
                              flex: 1,
                              marginRight: "20px",
                              minHeight: "240px",
                            }}
                          >
                            <TextField
                              variant="outlined"
                              id="dos"
                              name="dos"
                              label="Do's"
                              multiline
                              minRows={4}
                              required={false}
                              refs="dos"
                              placeholder="Do's"
                              onKeyUp={(event) => {
                                handleKeyUp("dos", event);
                              }}
                              style={{
                                height: "240px",
                                borderRadius: "4px",
                                borderColor: "#c4c4c4",
                                minHeight: "240px",
                              }}
                              defaultValue={dos}
                            />
                          </FormControl>
                          <FormControl
                            style={{
                              width: "220px",
                              marginBottom: "15px",
                              flex: 1,
                              marginRight: "20px",
                            }}
                          >
                            <TextField
                              variant="outlined"
                              id="donts"
                              name="donts"
                              label="Dont's"
                              multiline
                              minRows={4}
                              required={false}
                              refs="Dont"
                              placeholder="Dont's"
                              onKeyUp={(event) => {
                                handleKeyUp("donts", event);
                              }}
                              style={{
                                height: "auto",
                                borderRadius: "4px",
                                borderColor: "#c4c4c4",
                                minHeight: "240px",
                              }}
                              defaultValue={donts}
                            />{" "}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3}></Grid>
                        <br />
                      </div>
                    )}
                    {currentStep == 5 && (
                      <div>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ display: "flex", maxWidth: "100%" }}
                        >
                          <FormControl
                            style={{ width: "90%", marginBottom: "15px" }}
                          >
                            <Button
                              variant="contained"
                              className="btn btn-success float-right"
                              type="button"
                              id="demo-customized-button"
                              aria-controls={
                                open ? "demo-customized-menu" : undefined
                              }
                              onClick={faqList}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              style={{ float: "right", width: "100px" }}
                            >
                              {" "}
                              All FAQ
                            </Button>
                          </FormControl>
                          <FormControl
                            style={{ marginBottom: "30px", display: "inline" }}
                          >
                            <Button
                              variant="contained"
                              className="btn btn-success float-right"
                              type="button"
                              onClick={handleCloseFQA}
                            >
                              {" "}
                              + FAQ
                            </Button>
                          </FormControl>
                        </Grid>
                        <div style={{ maxHeight: "330px", overflow: "auto" }}>
                          {Array.from(Array(counter)).map((c, index) => {
                            return (
                              <div>
                                <FormControl
                                  style={{
                                    width: "100%",
                                    marginBottom: "15px",
                                    flex: 1,
                                    marginRight: "20px",
                                    marginTop: "5px",
                                  }}
                                >
                                  <TextField
                                    variant="outlined"
                                    required={false}
                                    type="text"
                                    value={faqQuestion[index]}
                                    id="question"
                                    refs="question"
                                    name="question"
                                    label="Question"
                                    onChange={handleQuestionOnChange(index)}
                                  />
                                </FormControl>
                                <Grid
                                  item
                                  xs={12}
                                  sm={6}
                                  style={{ display: "flex", maxWidth: "100%" }}
                                >
                                  <FormControl
                                    style={{
                                      width: "100%",
                                      marginBottom: "15px",
                                      flex: 1,
                                      marginRight: "20px",
                                    }}
                                  >
                                    <TextField
                                      variant="outlined"
                                      required={false}
                                      type="text"
                                      value={faqAnswer[index]}
                                      id="answer"
                                      refs="answer"
                                      name="answer"
                                      label="Answer"
                                      onChange={handleAnswerOnChange(index)}
                                    />
                                  </FormControl>
                                  <FormControl
                                    style={{
                                      marginBottom: "30px",
                                      display: "block",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <RemoveCircleOutlineIcon
                                      style={{ display: "block" }}
                                      onClick={handleRemoveClick(index)}
                                    />
                                  </FormControl>
                                </Grid>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    {currentStep == 6 && (
                      <div>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ display: "flex", maxWidth: "100%" }}
                        >
                          <FormControl
                            style={{
                              width: "220px",
                              marginBottom: "15px",
                              flex: 1,
                              marginRight: "20px",
                            }}
                          >
                            <Editor
                              editorState={editorState}
                              onEditorStateChange={handleEditorChange}
                              wrapperClassName="wrapper-class"
                              editorClassName="editor-class"
                              toolbarClassName="toolbar-class"
                              toolbar={{
                                options: ["image", "emoji", "link", "history"],
                                image: { inDropdown: true },
                                emoji: { inDropdown: true },
                                link: {
                                  inDropdown: true,
                                  showOpenOptionOnHover: true,
                                  defaultTargetOption: "_self",
                                  options: ["link", "unlink"],
                                },
                                history: { inDropdown: true },
                              }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={3}></Grid>
                      </div>
                    )}
                  </form>
                </React.Fragment>
              </Box>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            {draftButton()}
            {previousButton()}
          </div>
          <div>
            {nextButton()}
            {submitButton()}
          </div>
        </DialogActions>
      </BootstrapDialog>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={confirmationOpen}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        disableEscapeKeyDown
      >
        <Fade in={confirmationOpen}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              borderRadius: "15px",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography>
              <div style={{ marginBottom: 10 }}>
                This action will result in the loss of the data you have
                entered.
              </div>
              <div>Do you want to discard and exit?</div>
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "30px",
              }}
            >
              <Button
                variant="outlined"
                size="medium"
                type="button"
                onClick={handleConfirmationCancel}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="medium"
                type="button"
                onClick={handleConfirmationContinue}
              >
                Yes, Discard
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* Modal of All FAQ */}
      <Modal
        className="styles_modal"
        aria-labelledby="modal-add_influencer_to_list-label"
        aria-describedby="modal-add_influencer_to_list-description"
        open={faqmodalOpen}
        onClose={handleFaqClose}
        closeAfterTransition
      >
        <Fade in={faqmodalOpen}>
          <div
            class="syles_paper_list"
            style={{ height: "auto", width: "50%" }}
          >
            <div
              id="modal-add_influencer_to_list-heading"
              className="styles_modal_heading"
            >
              <h2
                id="modal-add_influencer_to_list-heading-text"
                style={{ padding: "10px" }}
              >
                All Faqs
              </h2>
              <IconButton onClick={handleFaqClose}>
                {" "}
                <CloseIcon />
              </IconButton>
            </div>
            <hr />
            <div
              id="modal-add_influencer_to_list-body"
              style={{ padding: "10px" }}
            >
              <Grid item xs={12}>
                <Box>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      display: "block",
                      maxWidth: "100%",
                      maxHeight: "160px",
                      overflow: "auto",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      style={{ display: "block", maxWidth: "100%" }}
                    >
                      <FAQS data={faqListData} />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={3}></Grid>
                </Box>
              </Grid>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};
export default ListAnalysis;
